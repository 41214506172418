'use client';

import { useInitAnalytics } from '../../hooks/useInitAnalytics';
import { Initializable } from '../../interfaces/Initializable';

type Props = {
  /**
   * @description 해당 페이지에서 사용할 애널리틱스의 service 객체들을 전달합니다.
   *
   * service 객체에는 함수가 포함되기 때문에 nextjs 의 app router 를 사용 중이라면 prop 을 전달하는 주체 컴포넌트가 client-component 여야 합니다.
   *
   * @example
   * ```tsx
   * 'use client';
   *
   * const HomeAnalytics = () => {
   *   return (
   *     ...
   *     <Analytics services={[firebase, amplitude, braze]} />
   *     ...
   *   )
   * };
   * ```
   */
  services: Initializable[];
};

export const Analytics = ({ services }: Props) => {
  useInitAnalytics(services);

  return <></>;
};
