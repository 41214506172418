import { Size } from '../ruler.types';

export enum SpinnerSizeEnum {
  small = 16,
  medium = 20,
  xLarge = 42,
}

export enum SpinnerStrokeEnum {
  small = 3,
  medium = 4,
  xLarge = 6,
}

export type SpinnerDimensions = {
  size: number;
  stroke: number;
};

export type SpinnerProps = {
  size?: Size;
  onColor?: boolean;
  isPending?: boolean;
};
