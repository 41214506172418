import { forwardRef } from 'react';
import { tv } from 'tailwind-variants';
import { Size } from '../ruler.types';
import { SpinnerDimensions, SpinnerProps, SpinnerSizeEnum, SpinnerStrokeEnum } from './Spinner.types';
import './spinner.css';

const getSpinnerDimension = (size: Size): SpinnerDimensions => {
  switch (size) {
    case 'xLarge':
      return {
        size: SpinnerSizeEnum.xLarge,
        stroke: SpinnerStrokeEnum.xLarge,
      };
    case 'large':
    case 'medium':
      return {
        size: SpinnerSizeEnum.medium,
        stroke: SpinnerStrokeEnum.medium,
      };
    case 'small':
    case 'xSmall':
      return {
        size: SpinnerSizeEnum.small,
        stroke: SpinnerStrokeEnum.small,
      };
    default:
      return {
        size: SpinnerSizeEnum.medium,
        stroke: SpinnerStrokeEnum.medium,
      };
  }
};

const PATH_LENGTH = 50;

export const spinnerVariant = tv({
  base: 'ruler-spinner',
  variants: {
    pending: {
      true: 'pending',
    },
  },
});

export const Spinner = forwardRef<SVGSVGElement, SpinnerProps>(
  ({ size = 'medium', onColor = false, isPending = true }, ref) => {
    const mergedClassName = spinnerVariant({ pending: isPending });
    const sizeDimension = getSpinnerDimension(size);

    return (
      <svg
        ref={ref}
        className={mergedClassName}
        xmlns="http://www.w3.org/2000/svg"
        width={sizeDimension.size}
        height={sizeDimension.size}
        viewBox={`-${sizeDimension.size / 2} -${sizeDimension.size / 2} ${sizeDimension.size} ${sizeDimension.size}`}
        style={{ '--path-length': PATH_LENGTH } as React.CSSProperties}
      >
        <circle
          r={Math.floor(sizeDimension.size / 2 - sizeDimension.stroke / 2)}
          strokeWidth={sizeDimension.stroke}
          fill="none"
          pathLength={PATH_LENGTH}
          className={onColor ? 'ruler-spinner-on-color' : 'ruler-spinner-normal'}
        ></circle>
      </svg>
    );
  },
);
