import { useUserContext } from '@29cm/contexts-users/features';
import { useEffect } from 'react';
import { Initializable } from '../interfaces/Initializable';

export const useInitAnalytics = (services: Initializable[]) => {
  const { user } = useUserContext();

  useEffect(() => {
    if (user?.no === undefined) {
      return;
    }

    services.forEach((service) => service.setUser(user.no));
  }, [services, user?.no]);

  useEffect(() => {
    services.forEach((service) => service.init());
  }, [services]);
};
