'use client';

import { trackClickTopAppInstallBannerEvent } from '@29cm/contexts-events/services';
import { generateAppInstallOneLink } from '@29cm/contexts-smart-link/services';
import { isMobileSafari } from '@29cm/services-runtime';
import { useEffect, useState } from 'react';
import { AppInstallBannerContent } from './AppInstallBannerContent';

export const AppInstallBanner = () => {
  const [hide, setHide] = useState(true);

  useEffect(() => {
    setHide(isMobileSafari());
  }, []);

  if (hide) {
    return null;
  }

  const trackClick = () => trackClickTopAppInstallBannerEvent({});

  const handleClick = () => {
    trackClick();
    document.location.href = generateAppInstallOneLink('TOP_BANNER');
  };

  return (
    <div className="cursor-pointer" onClick={handleClick}>
      <AppInstallBannerContent />
    </div>
  );
};
